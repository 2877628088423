import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvQuote from "../../Shared/DrvQuote/DrvQuote";
import DrvContent from "../../Shared/DrvContent/DrvContent";
import DrvSubFooter from "../../Shared/DrvSubFooter/DrvSubFooter";
import DrvCardTeam from "../../Shared/DrvCardTeam/DrvCardTeam";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {AboutUsData} from "./AboutUs.data";
import {Locations, SubFooterContent, SubFooterTitle} from "./AboutUs.styles";

import Cover from "../../../images/OurCompany/AboutUs/Cover.jpg";
import DrivosityUSA from "../../../images/OurCompany/AboutUs/DrivosityUSA.jpeg";
import DrivosityNorthMacedonia from "../../../images/OurCompany/AboutUs/DrivosityNorthMacedonia.jpeg";
import DrivositySerbia from "../../../images/OurCompany/AboutUs/DrivositySerbia.jpg";
import Brian from "../../../images/OurCompany/AboutUs/Brian.png";
import Scott from "../../../images/OurCompany/AboutUs/Scott.jpg";
import ScottSecond from "../../../images/OurCompany/AboutUs/ScottSecond.jpg";
import Sky from "../../../images/OurCompany/AboutUs/Sky.jpg";
import SkySecond from "../../../images/OurCompany/AboutUs/SkySecond.jpg";
import Berat from "../../../images/OurCompany/AboutUs/Berat.jpg";
import BeratSecond from "../../../images/OurCompany/AboutUs/BeratSecond.jpg";
import Edona from "../../../images/OurCompany/AboutUs/Edona.jpg";
import EdonaSecond from "../../../images/OurCompany/AboutUs/EdonaSecond.jpg";
import Ilir from "../../../images/OurCompany/AboutUs/Ilir.jpg";
import IlirSecond from "../../../images/OurCompany/AboutUs/IlirSecond.jpg";
import Ivan from "../../../images/OurCompany/AboutUs/Ivan.jpg";
import IvanSecond from "../../../images/OurCompany/AboutUs/IvanSecond.jpg";
import Bena from "../../../images/OurCompany/AboutUs/Bena.jpg";
import BenaSecond from "../../../images/OurCompany/AboutUs/BenaSecond.jpg";

const Team = [
    {
        name: "SCOTT TANIS",
        position: "Chief Operating Officer",
        contact: "Email: ",
        email: "scott@drivosity.com",
        linkedin: "https://www.linkedin.com/in/scott-tanis-67833026/",
        firstImage: Scott,
        secondImage: ScottSecond
    },
    {
        name: "SKY WESTON",
        position: "Director of Strategy & Investment",
        contact: "Email: ",
        email: "sky_weston@drivosity.com",
        linkedin: "https://www.linkedin.com/in/sky-weston/",
        firstImage: Sky,
        secondImage: SkySecond
    },
    {
        name: "EDONA AZIRI",
        position: "UI Designer",
        contact: "Email: ",
        email: "edona@drivosity.com",
        linkedin: "http://linkedin.com/in/aziriedona/",
        firstImage: Edona,
        secondImage: EdonaSecond
    },
    {
        name: "BERAT NEBIU",
        position: "Lead Web Developer & Support",
        contact: "Email: ",
        email: "berat_nebiu@drivosity.com",
        linkedin: "https://www.linkedin.com/in/berat-nebiu-23a45a174/",
        firstImage: Berat,
        secondImage: BeratSecond
    },
    {
        name: "ILIR HASHIMI",
        position: "Web Developer",
        contact: "Email: ",
        email: "ilir_hashimi@drivosity.com",
        linkedin: "https://www.linkedin.com/in/ilir-hashimi-bb0635176/",
        firstImage: Ilir,
        secondImage: IlirSecond
    },
    {
        name: "IVAN JEREMIC",
        position: "Data Scientist",
        contact: "Email: ",
        email: "ivan_jeremic@drivosity.com",
        linkedin: "https://www.linkedin.com/in/ivanjeremic/",
        firstImage: Ivan,
        secondImage: IvanSecond
    },
    {
        name: "BENA BEQIRI",
        position: "UI Designer",
        contact: "Email: ",
        email: "bena_beqiri@drivosity.com",
        linkedin: "https://www.linkedin.com/in/bena-beqiri-b39bb1143/",
        firstImage: Bena,
        secondImage: BenaSecond
    }
];

const AboutUs = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={AboutUsData[0].title}
                description={AboutUsData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="py-12">
                <DrvQuote
                    image={Brian}
                    name={AboutUsData[1].data.name}
                    position={AboutUsData[1].data.position}
                    quote={AboutUsData[1].content}
                />
            </div>

            {/*CONTENT*/}
            <div className='container mx-auto drv-description'>
                {AboutUsData[2].content}
            </div>

            {/*CONTENT*/}
            <DrvContent
                type={['text']}
                content={[
                    (
                        <>
                            <div className='drv-subtitle'>
                                {AboutUsData[3].title}
                            </div>
                            <div className='drv-description'>
                                {AboutUsData[3].content}
                            </div>
                        </>
                    )
                ]}
            />

            {/*CONTENT*/}
            <div>
                <div className='container mx-auto drv-subtitle'>
                    ONE TEAM. GLOBAL PRESENCE
                </div>
                <div className="flex justify-center" style={{backgroundColor: '#f8f8f8'}}>
                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        <div>
                            <Locations>US HEADQUARTERS</Locations>
                            <img src={DrivosityUSA} alt="USA Team" />
                        </div>
                        <div>
                            <Locations>NORTH MACEDONIA OFFICE</Locations>
                            <img src={DrivosityNorthMacedonia} alt="North Macedonia Team" />
                        </div>
                        <div>
                            <Locations>SERBIA OFFICE</Locations>
                            <img src={DrivositySerbia} alt="Serbia Team" />
                        </div>
                    </div>
                </div>
            </div>

            {/*CONTENT*/}
            <div>
                <DrvContent
                    type={['text']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {AboutUsData[4].title}
                                </div>
                                <div className='drv-description'>
                                    {AboutUsData[4].content}
                                </div>
                            </>
                        )
                    ]}
                />
                <div className="container mx-auto flex justify-center pb-12">
                    <div className="grid-rows-1">
                        <div className="flex justify-center">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2" >
                                <DrvCardTeam team={[Team[0]]} />
                            </div>
                        </div>
                        {/* <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
                            <DrvCardTeam team={[Team[2], Team[3], Team[4]]} />
                        </div>
                        <div className="flex justify-center">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                <DrvCardTeam team={[Team[5], Team[6]]} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            {/*CONTENT*/}
            <DrvSubFooter
                color="#ffffff"
                background="var(--secondary-color)"
                description={
                    <div className='flex flex-col'>
                        <SubFooterTitle>{AboutUsData[5].title}</SubFooterTitle>
                        <SubFooterContent>{AboutUsData[5].content}</SubFooterContent>
                    </div>
                }
                button={
                    <DrvButton to="/careers" background="var(--secondary-color)" bordercolor="white" className="w-fit">LEARN MORE</DrvButton>
                }
            />
        </>
    )
}
export default AboutUs;
